import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Introduction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Color contrast</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Low vision</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Tools</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
        <ResourceCard subTitle="Carbon color contrast tool" href="https://marijohannessen.github.io/color-contrast-checker/" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Visual impairments can include low vision, color blindness, and complete blindness. Carbon components have been designed to accommodate the entire spectrum of visual impairment, though designers still need to exercise diligence to ensure that the components are used correctly.`}</p>
    <h2 {...{
      "id": "color-contrast"
    }}>{`Color contrast`}</h2>
    <p>{`Carbon meets `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html"
      }}>{`WCAG AA standards`}</a>{` across all standard themes in the system, including color contrast ratios.`}</p>
    <h4 {...{
      "id": "standard-text"
    }}>{`Standard text`}</h4>
    <p>{`Standard text and images of text must have a contrast ratio of at least `}<strong parentName="p">{`4.5:1`}</strong>{`.`}</p>
    <h4 {...{
      "id": "large-text"
    }}>{`Large text`}</h4>
    <p>{`Large text (at least 24 px regular and light / 19 px semi-bold) must have a contrast ratio of `}<strong parentName="p">{`3:1.`}</strong></p>
    <h4 {...{
      "id": "text-against-non-static-backgrounds"
    }}>{`Text against non-static backgrounds`}</h4>
    <p>{`When text is rendered on a gradient background or image, make sure the text color meets contrast standards in all places it appears. This is especially important for parallax applications or animations where text and backgrounds are moving independently of each other.`}</p>
    <h4 {...{
      "id": "ui-components"
    }}>{`UI components`}</h4>
    <p>{`Visual information used to indicate states and boundaries of UI components must have a contrast ratio of `}<strong parentName="p">{`3:1`}</strong>{` against adjacent colors. A UI component is defined as “a part of the content that is perceived by users as a single control for a distinct function.” This includes form elements.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ibm.com/able/guidelines/ci162/contrast.html"
      }}>{`IBM Checkpoint 1.4.3 Contrast (Minimum)`}</a></p>
    <p>{`Please refer to our `}<a parentName="p" {...{
        "href": "/guidelines/color/usage"
      }}>{`usage section on the Colors page`}</a>{` to learn more about color contrast ratios. You can also use this `}<a parentName="p" {...{
        "href": "https://marijohannessen.github.io/color-contrast-checker/"
      }}>{`color contrast`}</a>{` tool to ensure your ratios pass WCAG AA standards.`}</p>
    {
      /* To view best practices for using color in data visualization, view the [Data Vis color](/data-visualization/overview/colors) page. */
    }
    <h2 {...{
      "id": "color-blindness"
    }}>{`Color blindness`}</h2>
    <p>{`Color blindness involves difficulty in perceiving or distinguishing between colors, as well as sensitivity to color brightness. It affects approximately one in twelve men and one in two hundred women worldwide.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color deficiency`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Protanopia`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Red/green`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Tritanopia`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Deuteranopia`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Green`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Monochromacy`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`All colors`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "designing-for-color-blindness"
    }}>{`Designing for color blindness`}</h3>
    <p>{`Don’t rely on color alone to convey meaning. This includes conveying information, indicating an action, prompting the user for a response, or distinguishing one visual element from another. When designing with color, it might help to use a color-blind simulator to review visibility of content. If you’re working in Sketch, we recommend the `}<a parentName="p" {...{
        "href": "http://www.getstark.co/"
      }}>{`Stark`}</a>{` plugin.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAAB4UlEQVQoz12STWsTURSGu1b8FYK/wKW4die6EdzVLpRBkQSjRaSaQSqIrVKzCsXmy44GHCTQqtQPKhSjJB1bbWM1nTAfmZk7M3e+MrG2k8nxVJHanNXDged9L/feIenceU9VI4C3LwuLr7k+gK78qExfcm3S7UP7wZTxpLwDsNFoZDIZz/PCMOz8m6G1wyccUQKAwqPsbGkGQWquP7x6ihJ9C2CTuSjdm4wBhHo9mUy6rtvtdvdk/njVlByAX2P59dtcA2BnTdROp8uGRXsQVyan3nNlTPyyusreYlHe13zsbF9UQoBweIYwJRPgZ120j7Af2pYLcXS5wN2ff4WyIHwevTY6KB9IyN/bPsDW9dxm+rGIR1gR7aNs9a98psiN/ZFry8uJRGLw2IduFpsaFsbZ/EJu9h1Af0PURthnhuVALxopPR1/sYByXRBSqdRg88EbJ5va7oXls+NcbgKh1fx258qwaRKI4wvTxbuVeVx+qtUYhhlsTs9NaJbei+DjUqVWnevFQHT5DZ91HRptbz9fqi6ufMWUVqvF87zn+fuaHcPBQbIpvreO2X4QqBpR1XbQ6VDT0lTFRcn3DYPIsoywJ0uKbFlWEASEGIoio4w/gVIbE3fBcSilCLhHwOX/8m8btPbWd2jJrgAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "rainbow palette",
        "title": "rainbow palette",
        "src": "/static/5262dffea372f7e4a577ff3ec2a4539e/fb070/color-accessibility-1.png",
        "srcSet": ["/static/5262dffea372f7e4a577ff3ec2a4539e/d6747/color-accessibility-1.png 288w", "/static/5262dffea372f7e4a577ff3ec2a4539e/09548/color-accessibility-1.png 576w", "/static/5262dffea372f7e4a577ff3ec2a4539e/fb070/color-accessibility-1.png 1152w", "/static/5262dffea372f7e4a577ff3ec2a4539e/fb104/color-accessibility-1.png 1728w", "/static/5262dffea372f7e4a577ff3ec2a4539e/8fefe/color-accessibility-1.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "low-vision"
    }}>{`Low vision`}</h2>
    <p>{`Low vision can include partial sight in one or both eyes and can range from mild to severe. It affects about 4% of the world’s population.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Visual deficiency`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Low acuity`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Also known as “blurred vision.” Can make text difficult to read, since it appears fuzzy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Low-contrast sensitivity`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decreased ability to determine fine detail and distinguish one object from another`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Obstructed visual field`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user’s view is partially obstructed. Can include central vision and spotty vision.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Retinitis Pigmentosa`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Also known as “tunnel vision.” The user is only able to see central elements.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "low-vision-disabilities"
    }}>{`Low-vision disabilities`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.214285714285715%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABUUlEQVQoz6VSzYqCUBh15UpBqIW4auGutQ/QG7R22yJ8h5YGvU+LsMiUlEAHcVHQHwQlDAS1qKnUcpyjd37cTDDMWVw+7nfPOd/Ppd5/R5qmT1IAlf4D1G63c13X87yXAhzHWSwWt9ttMplYlmUYhq7rw+FwNBpZOY7HY0ZeLpfj8ZgkjBymaQ4Gg9lstlqtOI6rVCqyLLfb7U6n02g0qtUqTdP9fj8j3+/3JEniOC7WgxucMC+VSvV63bbtJMd0OlUUhWGYT/JbjtPpFEVRGIaPxwNyiAmZ5/lms7nZbIjH4XBQVRWKmqZlZMK5XC7kJBPGUwToqFwuwxm97Pf76/Xq+z6cWZb9KRt8OKPy6AsYFXLr9VoURUmSWq1Wt9vt9Xpou1arCYIAuYx8Pp8xOqh+M8McRHQ+n8M/CILXHAi22y0WRNSp4tL/vOfn6ec/7ANVUDUn8uTUcAAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "blurred vision and tunnel vision example on Google website",
        "title": "blurred vision and tunnel vision example on Google website",
        "src": "/static/cac4d2304193cd2215ca6b53d9579852/fb070/color-accessibility-2.png",
        "srcSet": ["/static/cac4d2304193cd2215ca6b53d9579852/d6747/color-accessibility-2.png 288w", "/static/cac4d2304193cd2215ca6b53d9579852/09548/color-accessibility-2.png 576w", "/static/cac4d2304193cd2215ca6b53d9579852/fb070/color-accessibility-2.png 1152w", "/static/cac4d2304193cd2215ca6b53d9579852/89965/color-accessibility-2.png 1680w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "designing-for-low-vision"
    }}>{`Designing for low vision`}</h3>
    <p>{`To get an understanding of the various low-vision disabilities, we recommend using the `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/nocoffee/jjeeggmbnhckmgdhmgdckeigabjfbddl"
      }}>{`NoCoffee`}</a>{` Chrome plugin to preview websites. Low-vision users may be using a screen reader to preview your website or experience, so be sure to follow `}<a parentName="p" {...{
        "href": "/guidelines/accessibility/keyboard"
      }}>{`keyboard guidelines`}</a>{` to ensure the page is read to the user in a logical order.`}</p>
    <h2 {...{
      "id": "resources-1"
    }}>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://w3.ibm.com/able/devtest/quick/"
        }}>{`IBM Accessibility quick guidance`}</a>{`(Internal only)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.ibm.com/able/guidelines/ci162/accessibility_checklist.html"
        }}>{`IBM Web Accessibility Checklist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/WCAG21/"
        }}>{`WCAG 2.1 guidelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/low-vision-needs/"
        }}>{`W3C low vision requirements`}</a></li>
    </ul>
    <h2 {...{
      "id": "tools"
    }}>{`Tools`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/high-contrast/djcfdncoelnlbldjfhinnjlhdjlikmph/related?hl=en"
        }}>{`High Contrast Chrome plugin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.getstark.co/"
        }}>{`Stark Sketch plugin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chrome.google.com/webstore/detail/nocoffee/jjeeggmbnhckmgdhmgdckeigabjfbddl"
        }}>{`NoCoffee vision simulator`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      